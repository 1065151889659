<template>
	<div class="news-form">
        <div class="wrapper">
            <div class="item-category" @click="chooseCategory">
                <div class="hint">文章分类</div>
                <div class="value" :data-value="mCategory.codeid" v-text="mCategory.codedesc || '未选择'"></div>
                <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-more">
            </div>
            <div class="item-title">
                <div class="hint">请编辑您的文章标题(20字以内)</div>
                <input type="text" class="value" placeholder="点击输入您的内容" v-model="formData.title">
            </div>
            <div class="item-second-title">
                <div class="hint">请输入文章摘要、导读文本内容</div>
                <input type="text" class="value" placeholder="点击输入您的内容" v-model="formData.introduce">
            </div>
        </div>
        <div class="item-is-owner">
            <div class="hint">请问您的文章是否为原创</div>
            <div class="checkbox">
                <span @click="setFormData('isoriginal', true)" :class="{'cb-item': true, 'checked': formData.isoriginal == true}">是</span>
                <span @click="setFormData('isoriginal', false)" :class="{'cb-item': true, 'checked': formData.isoriginal == false}">否</span>
            </div>
        </div>
        <div class="is-owner-box" v-if="formData.isoriginal == false">
            <div class="author">
                <label class="hint" for="author-v">请输入原作者</label>
                <input type="text" class="value" id="author-v" v-model="formData.author">
            </div>
            <div class="web-name">
                <label class="hint" for="web-name-v">文章来源网站名稱</label>
                <input type="text" class="value" id="web-name-v" v-model="formData.copyfrom">
            </div>
            <div class="web-url">
                <label class="hint" for="web-url-v">文章来源网站网址</label>
                <input type="text" class="value" id="web-url-v" v-model="formData.fromurl">
            </div>
        </div>
        <div class="item-content">
            <div class="hint">请输入文章正文</div>
            <textarea cols="30" rows="5" type="text" class="value" placeholder="点击输入您的内容" v-model="formData.content"></textarea>
        </div>
        <div class="item-tags">
            <label class="hint" for="item-tags-v">輸入關鍵字(以逗號間隔)</label>
            <input type="text" class="value" id="item-tags-v" v-model="formData.tag">
        </div>
        <div class="item-calinic" @click="chooseClinic">
            <div class="hint">请选择诊所</div>
            <div class="value" :data-value="mClinic.clinicid" v-text="mClinic.clinicname"></div>
            <img src="../../../../assets/imgs/mobile/user_center/icon_more@2x.png" alt="" class="icon-more">
        </div>
		<div class="item-photo" style="display: none;">
			<div class="hint">添加文章配图</div>
			<div class="photos">
				<div class="photo empty" @click="pickImage">
					<img src="../../../../assets/imgs/mobile/user_center/icon_tackphoto@2x.png" />
					<div class="lds-ring1" v-if="loading"><div></div><div></div><div></div><div></div></div>
					<input ref="image" type="file" src="" alt="">
				</div>
			</div>
		</div>
		<div class="btn-submit" @click="addNews"><div class="lds-ring1" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '發佈文章'}}</div>
		<van-popup
            v-model="showCategoryWindow"
            closeable
            position="bottom"
            :style="{ height: '30%' }"
        >
			<div class="category-loading-box" v-if="loadingCategory">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
            <div class="category-list">
				<div @click="onCategoryItemClick(category)" class="category-item" v-for="(category, index) in categorys" :key="index" v-text="category.codedesc"></div>
            </div>
        </van-popup>

		<van-popup
            v-model="showClinicWindow"
            closeable
            position="bottom"
            :style="{ height: '30%' }"
        >
			<div class="clinic-loading-box" v-if="loadingClinic">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
            <div class="clinic-list">
				<div @click="onClinicItemClick(clinic)" class="clinic-item" v-for="(clinic, index) in clinics" :key="index" v-text="clinic.clinicname"></div>
            </div>
        </van-popup>
    </div>
</template>

<script>
	import add_news from '@/views/mixins/add_news'
	export default {
		mixins: [
			add_news
		],
		data(){
			return {
				showCategoryWindow: false,
				showClinicWindow: false,
			}
		},
		mounted: function(){
			this.formData.isoriginal = true
		},
		methods: {
			chooseCategory: function(){
				this.showCategoryWindow = true
				this.loadCategorys()
			}, 
			chooseClinic: function(){
				this.showClinicWindow = true
				this.getClinicsByDoctor()
			},
            setFormData: function(k, v){
                this.formData[k] = v
			},
			onCategoryItemClick: function(v) {
				this.mCategory = v
				this.setFormData('catid', v.codeid)
				this.showCategoryWindow = false
			},
			onClinicItemClick: function(v) {
				this.mClinic = v
				this.setFormData('clinicid', v.clinicid)
				this.showClinicWindow = false
			},
			pickImage: function(){
				// this.$refs.image.click()
				// var formData = new FormData()
                // formData.append("files", [file])
                // this.$store.dispatch('uploadImage', {
                //     imgCategory: 6, form: formData
                // }).then((rs)=>{
                //     // if(rs.code == 200) {
                //     //     var imgList = rs.data || []
                //     //     //把获取到的图片url 插入到鼠标所在位置 回显图片
                //     //     imgList.forEach(img => Editor.insertEmbed(cursorLocation, 'image', img))
                //     // } else {
                //     //     // this._toast.warning(this, rs.msg)
                //     // }
                // }).catch(err=>{
                // })
			}
		},
		created: function(){
			var userInfo = this.$store.getters.userInfo
			var isDoctorUser = !! (userInfo && userInfo.doctorglobaluserid)
			if(!isDoctorUser) {
				this.$router.push('/user/home')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.news-form {
		margin-top: 16px;
		display: flex;
		flex-direction: column;
	}

	.news-form .wrapper {
		background-color: #fff;
		padding: 32px 24px;
		display: flex;
		flex-direction: column;
	}

	.news-form .item-category {
		display: flex;
		flex-direction: row;
		height: 72px;
		align-items: center;
		justify-content: space-between;
		position: relative;
		padding-right: 49px;
		border-radius: 8px;
		border: 1px solid #00000000;
		padding-left: 17px;
		background-color: #F2F5F9;
		color: #999999;
		font-size: 28px;
		font-weight: 400;
	}

	.news-form .item-category:hover {
		background-color: #fff;
		border-color: #36C4D0;
	}

	.news-form .item-category > .icon-more {
		position: absolute;
		right: 17px;
		width: 8px;
		height: 17px;
		top: 50%;
		transform: translateY(-50%);
	}

	.news-form .item-category > .value {
		color: #1F1F1F;
		font-size: 34px;
		font-weight: bold;
	}

	.news-form .item-category > .value[data-value=''] {
		color: #999999;
		font-size: 28px;
		font-weight: 400;
	}

	.news-form .item-second-title,
	.news-form .item-title {
		display: flex;
		flex-direction: column;
		margin-top: 40px;
	}

	.news-form .item-second-title > .hint,
	.news-form .item-title > .hint {
		color: #333333;
		font-size: 32px;
		line-height: 31px;
		font-weight: bold;
	}

	.news-form .item-content > .value::placeholder,
	.news-form .item-second-title > .value::placeholder,
	.news-form .item-title > .value::placeholder {
		color: #999999;
		font-size: 28px;
		font-weight: 400;
	}

	.news-form .item-second-title > .value,
	.news-form .item-title > .value {
		color: #1F1F1F;
		font-size: 34px;
		font-weight: bold;
		height: 72px;
		line-height: 72px;
		text-align: left;
		padding: 0px 17px;
		background-color: #F2F5F9;
		border-radius: 8px;
		margin-top: 19px;
	}

	.news-form .item-is-owner {
		display: flex;
		flex-direction: row;
		height: 96px;
		align-items: center;
		background-color: #fff;
		margin-top: 16px;
		padding: 0px 24px;
	}

	.news-form .item-is-owner > .hint {
		flex: 1;
		color: #999999;
		font-weight: 400;
		font-size: 28px;
	}

	.news-form .checkbox {
		display: flex;
		flex-direction: row;
		width: 144px;
		height: 64px;
	}

	.news-form .checkbox > .cb-item {
		flex: 1;
		height: 100%;
		background-color: #E8E8E8;
		height: 65px;
		color: #333333;
		font-size: 28px;
		font-weight: 400;
		text-align: center;
		line-height: 64px;
	}

	.news-form .checkbox > .cb-item.checked {
		background-color: #36C4D0;
		font-weight: bold;
		color: #fff;
	}

	.news-form .web-name,
	.news-form .web-url,
	.news-form .author {
		height: 97px;
		line-height: 97px;
		background-color: #e8e8e8;
		padding: 0px 25px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		position: relative;
		box-sizing: border-box;
		border-bottom: 1px solid #e8e8e8;
	}

	.news-form .author::after,
	.news-form .web-name::after {
		content: '';
		position: absolute;
		left: 25px;
		bottom: 0;
		right: 25px;
		height: 1px;
		background-color: #B7B7B7;
	}

	.news-form .web-name > .value,
	.news-form .web-url > .value,
	.news-form .author > .value {
		flex: 1;
		background-color: transparent;
		text-align: right;
		color: #1F1F1F;
		font-size: 34px;
		font-weight: bold;
	}

	.news-form .item-content {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		padding: 24px;
		margin-top: 16px;
	}

	.news-form .item-content > .hint {
		color: #333333;
		font-size: 32px;
		font-weight: bold;
		line-height: 31px;
	}

	.news-form .item-content > .value {
		height: 118px;
		box-sizing: border-box;
		padding: 23px 17px;
		color: #1F1F1F;
		font-size: 34px;
		font-weight: bold;
		background-color: #F2F5F9;
		border-radius: 8px;
		margin-top: 19px;
	}

	.news-form .item-tags {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 97px;
		margin-top: 16px;
		background-color: #fff;
		align-items: center;
		padding: 0px 25px;
	}

	.news-form .item-tags::after {
		position: absolute;
		content: '';
		left: 25px;
		right: 25px;
		bottom: 0;
		height: 1px;
		background-color: #b7b7b7;
	}

	.news-form .item-tags > .hint {
		color: #999999;
		font-weight: 400;
		font-size: 28px;
		line-height: 27px;
		white-space: nowrap;
	}

	.news-form .item-tags > .value {
		color: #333333;
		font-size: 32px;
		font-weight: bold;
		line-height: 33px;
		flex: 1;
		text-align: right;
	}

	.news-form .item-calinic {
		height: 97px;
		line-height: 97px;
		display: flex;
		flex-direction: row;
		position: relative;
		align-items: center;
		justify-content: space-between;
		background-color: #fff;
		padding: 0px 73px 0px 25px
	}

	.news-form .item-calinic > .hint {
		color: #999999;
		font-size: 28px;
		font-weight: 400;
	}

	.news-form .item-calinic > .value {
		color: #1F1F1F;
		font-weight: bold;
		font-size: 34px;
	}
	
	.news-form .item-calinic > .icon-more {
		position: absolute;
		right: 40px;
		top: 50%;
		transform: translateY(-50%);
		height: 26px;
		width: 12px;
	}

	.news-form .btn-submit {
		width: 478px;
		height: 96px;
		line-height: 96px;
		text-align: center;
		border-radius: 48px;
		border: 1px solid #36C4D0;
		background-color: #ededed;
		color: #36C4D0;
		font-size: 34px;
		font-weight: bold;
		margin: 60px auto 80px auto;
	}

	.news-form .btn-submit:active {
		opacity: 0.7;
	}

	// 文章分类 start
	.category-list {
		padding-top: 80px;
	}
	.category-list > .category-item {
		height: 72px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding: 0px 24px;
		box-sizing: border-box;
		border-bottom: 1px solid #e8e8e8;
		font-size: 28px;
		font-weight: 400;
		color: #636363;
		background-color: #fff;
	}
	.category-list > .category-item:active {
		background-color: #f7f7f7;
	}
	// 文章分类 end


	// 诊所列表 start
	.clinic-list {
		padding-top: 80px;
	}
	.clinic-list > .clinic-item {
		height: 72px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding: 0px 24px;
		box-sizing: border-box;
		border-bottom: 1px solid #e8e8e8;
		font-size: 28px;
		font-weight: 400;
		color: #636363;
		background-color: #fff;
	}
	.clinic-list > .clinic-item:active {
		background-color: #f7f7f7;
	}
	// 诊所列表 end

    // category loading start
    .lds-ring {
        display: inline-block;
        width: 80px;
        height: 80px;
        align-self: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #36C4D0;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #36C4D0 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    // category loading end

	/** loading start */
    .lds-ring1 {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring1 div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring1 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring1 div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring1 div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring1 div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring1 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    /** loading end */ 

	.item-photo {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		margin-top: 16px;
		padding: 24px;
	}

	.item-photo > .hint {
		color: #333333;
		font-size: 32px;
		font-weight: bold;
		line-height: 31px;
		margin-bottom: 19px;
	}
	.photos > .photo {
		width: 80px;
		height: 80px;
		border-radius: 8px;
		background-color: #F1F5F9;
		display: inline-block;
		margin-right: 12px;
	}
	.photos > .photo > img {
		width: 100px;
		height: 100%;
	}
	.photos > .photo.empty > img {
		width: 60px;
		height: 60px;
		margin: 10px;
	}
</style>
